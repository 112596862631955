<script>
import windowProjeto from '@/components/suporte/components/projetos/window/projeto'
import EBtnTableOptions from "@/plugins/uloc-erp/components/button/EBtnTableOption"
import MenuOptions from "components/layout/context-menu/context-window-options"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item"
import TaskListPage from "components/suporte/components/task/ListPage"
export default {
  name: 'SuporteProjeto',
  inject: ['container'],
  mixins: [],
  components: {
    TaskListPage,
    MenuOptionsItem,
    MenuOptions,
    EBtnTableOptions
  },
  data () {
    return {
      routeName: ['suporte.projetos.projeto', 'suporte.projetos.projeto.fila']
    }
  },
  mounted() {
    if (this.$route.name === 'suporte.projetos.projeto.fila') {
      this.container.$refs.menuProjeto.menu = 'fila'
    }
  },
  computed: {
    projeto () {
      return this.container.projeto
    }
  },
  methods: {
    windowProjeto: windowProjeto,
    editarProjeto () {
      this.windowProjeto(this.projeto.id)
    }
  }
}
</script>

<template>
  <div v-if="routeName.includes($route.name)">
    <div class="flex justify-between">
      <ul class="snav">
        <li><a @click="$router.push({name: 'suporte.dashboard'})">Suporte</a></li>
        <li><a @click="$router.push({name: 'suporte.projetos'})">Projetos</a></li>
        <li v-if="projeto.type" class="disable cursor-pointer">{{ projeto.type.name }}</li>
        <li class="disable cursor-pointer">{{ projeto.name }}</li>
      </ul>
      <div>
        <e-btn-table-options class="e-btn-bg" icon-name="ellipsis-h">
          <menu-options>
            <ul>
              <menu-options-item close label="Editar projeto" @click="editarProjeto" />
            </ul>
          </menu-options>
        </e-btn-table-options>
      </div>
    </div>
    <div class="stitle">
      <h2 class="cursor-pointer" @click="container.load">{{ container.menuActive.label }}</h2>
      <div class="sopts">
      </div>
    </div>
    <task-list-page :fila="container.menuActive.name" :project="projeto.id" />
  </div>
  <router-view v-else />
</template>
